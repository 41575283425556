
















































import { Component, Prop, Vue } from 'vue-property-decorator';
import VideosApi from '@/api/Videos';

@Component
export default class VideoTest extends Vue {
  @Prop() videoId: string;
  public frames: string[] = [];
  public progressBarRef: HTMLElement;
  public currentFrame = 0;
  public imgWidth = 0;
  public imgHeight = 0;
  public isPlaying = false;
  public progressHoverWidth = 0;
  public hoverFrame = 0;
  public speeds = [.25, .5, .75, 1, 1.25, 1.5, 1.75, 2];
  public selectedSpeed = 1;
  
  private timerId: number;

  created() {
    window.addEventListener('keydown', this.handleKeypress);
  }

  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeypress);
  }

  public handleKeypress($event: KeyboardEvent) {
    if ($event.code === 'ArrowRight') {
      this.incrementFrame();
    } else if ($event.code === 'ArrowLeft') {
      this.decrementFrame();
    }
  }

  mounted() {
    VideosApi.getVideo(parseInt(this.videoId)).then((response) => {
      console.log(response);
      this.frames = response.data;
    }).catch((error) => {
      this.$root.$emit('handleError', error);
    });

    this.progressBarRef = this.$refs.progressBar as HTMLElement;
    this.progressBarRef.onmousemove = (e: MouseEvent) => {
      this.progressHoverWidth = e.offsetX;
      const percent = this.progressHoverWidth / this.progressBarRef.offsetWidth;
      this.hoverFrame = Math.round(this.frames.length * percent);
    }
  }

  public selectSpeed(speed: number) {
    this.selectedSpeed = speed;
    const speedSelectBtnRef: HTMLButtonElement = this.$refs.speedSelectBtn as HTMLButtonElement;
    speedSelectBtnRef.blur();

    // if already playing when speed is changed,
    // toggle twice so the setInterval() gets the
    // new speed setting
    if (this.isPlaying) {
      this.togglePlay();
      this.togglePlay();
    }
  }

  public togglePlay() {
    this.isPlaying = !this.isPlaying;
    if (this.isPlaying) {
      this.timerId = setInterval(() => {
        this.incrementFrame();
      }, (1/30) * 1000 / this.selectedSpeed);
    } else {
      clearInterval(this.timerId);
    }
  }

  public incrementFrame() {
    if (this.currentFrame >= this.frames.length - 1) {
      if (this.isPlaying) this.togglePlay();
      return;
    }
    this.currentFrame++;
  }

  public decrementFrame() {
    if (this.currentFrame <= 0) {
      if (this.isPlaying) this.togglePlay();
      return;
    }
    this.currentFrame--;
  }

  public jumpToFrame() {
    this.currentFrame = this.hoverFrame;
  }

  public get progressWidth() {
    const progress = this.currentFrame / this.frames.length * 100;
    return `${progress}%`;
  }

  public get hoverTime() {
    const totalSec = Math.round(this.hoverFrame * 1/30);
    const min = String(Math.floor(totalSec / 60)).padStart(2, '0');
    const sec = String(totalSec % 60).padStart(2, '0');
    return `${min}:${sec}`;
  }

  public get currentTime() {
    const totalSec = Math.round(this.currentFrame * 1/30);
    const min = String(Math.floor(totalSec / 60)).padStart(2, '0');
    const sec = String(totalSec % 60).padStart(2, '0');
    return `${min}:${sec}`;
  }

  public get totalTime() {
    const totalSec = Math.round(this.frames.length * 1/30);
    const min = String(Math.floor(totalSec / 60)).padStart(2, '0');
    const sec = String(totalSec % 60).padStart(2, '0');
    return `${min}:${sec}`;
  }
}
